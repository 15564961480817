import React  from 'react';
import PropTypes from 'prop-types';

import { Route } from 'react-router-dom';
import routes from '../../routes';
import { Responsive, Sidebar } from 'semantic-ui-react';
import OfisoFooter from '../layouts/ofisoFooter';
import OfisoMenu from './ofisoMenu';
import OfisoSidebar from './ofisoSidebar';

const getWidth = () => {
    const isSSR = typeof window === 'undefined';

    return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
};

function DesktopContainer(props) {
    return (
        <Responsive className={"site"} getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
            <OfisoMenu/>
            {props.children}
            <OfisoFooter/>
        </Responsive>
    );
}

DesktopContainer.propTypes = {
    children: PropTypes.node,
};

function MobileContainer(props) {
    return (
        <Responsive
            as={Sidebar.Pushable}
            getWidth={getWidth}
            maxWidth={Responsive.onlyMobile.maxWidth}
        >
            <OfisoSidebar>
                {props.children}
            </OfisoSidebar>
        </Responsive>
    )
}

MobileContainer.propTypes = {
    children: PropTypes.node,
};

const ResponsiveContainer = ({ children }) => (
    <div>
        <DesktopContainer>{children}</DesktopContainer>
        <MobileContainer>{children}</MobileContainer>
    </div>
);

function App() {
    return (
        <ResponsiveContainer>
            <main className={"site-content"}>
                {
                    routes.map(route => (
                        <Route key={route.path} {...route} />
                    ))
                }
            </main>
        </ResponsiveContainer>
    )
}

export default App;
