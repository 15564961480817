import { Container, Header, Segment } from 'semantic-ui-react';
import React from 'react';
import OfisoHeader from '../layouts/ofisoHeader';

function Impressum() {
    return (
        <Segment style={{ padding: '0em' }} vertical>
            <OfisoHeader title={"Impressum"}/>
            <Container style={{ padding: '4em 0em' }}>
                <Segment>
                    <Header as='h3'>
                        Siegfried Engel
                    </Header>
                    <p>
                        M&ouml;rikestr. 21 <br />
                        72116 M&ouml;ssingen <br />
                        Tel 07473/24962 <br />
                        Telefax 07473 / 920673 <br />
                        Mail: info@ofiso.de <br />
                    </p>
                </Segment>

                <Segment>
                    <p><strong>Datenschutzerklärung</strong></p>
                    <p><strong>Anbieter und verantwortliche Stelle im Sinne des Datenschutzgesetzes</strong></p>
                    <p>OFISO Software<br/>Siegfried Engel<br/>Mörikestr. 21<br/>72116 Mössingen</p>
                    <p><strong>Geltungsbereich</strong></p>
                    <p>Nutzer erhalten mit dieser Datenschutzerklärung Information über die Art, den Umfang und Zweck der Erhebung und Verwendung ihrer Daten durch den verantwortlichen Anbieter erhoben und verwendet werden. </p>
                    <p>Den rechtlichen Rahmen für den Datenschutz bilden das Bundesdatenschutzgesetz (BDSG) und das Telemediengesetz (TMG).</p>
                    <p><strong>Erfassung allgemeiner Informationen</strong></p>
                    <p>Mit jedem Zugriff auf dieses Angebot werden durch uns bzw. den Webspace-Provider automatisch Informationen erfasst. Diese Informationen, auch als Server-Logfiles bezeichnet, sind allgemeiner Natur und erlauben keine Rückschlüsse auf Ihre Person. </p>
                    <p>
                        Erfasst werden unter anderem: Name der Webseite, Datei, Datum, Datenmenge, Webbrowser und Webbrowser-Version, Betriebssystem, der Domainname Ihres Internet-Providers, die sogenannte Referrer-URL (jene Seite, von der aus Sie auf unser Angebot zugegriffen
                        haben) und die IP-Adresse.</p>
                    <p>Ohne diese Daten wäre es technisch teils nicht möglich, die Inhalte der Webseite auszuliefern und darzustellen. Insofern ist die Erfassung der Daten zwingend notwendig. Darüber hinaus verwenden wir die anonymen Informationen für statistische Zwecke. Sie
                        helfen uns bei der Optimierung des Angebots und der Technik. Wir behalten uns zudem das Recht vor, die Log-Files bei Verdacht auf eine rechtswidrige Nutzung unseres Angebotes nachträglich zu kontrollieren.</p>
                    <p><strong>Kontaktformular</strong></p>
                    <p>Wenn Sie uns über das Onlineformular oder per E-Mail kontaktieren, speichern wir die von Ihnen gemachten Angaben, um Ihre Anfrage beantworten und mögliche Anschlussfragen stellen zu können.</p>
                    <p><strong>Ihre Rechte auf Auskunft, Berichtigung, Sperre, Löschung und Widerspruch</strong></p>
                    <p>Sie haben das Recht, auf Antrag unentgeltlich eine Auskunft, über die bei uns gespeicherten personenbezogenen Daten, anzufordern und/oder eine Berichtigung, Sperrung oder Löschung zu verlangen. Ausnahmen: Es handelt sich um die vorgeschriebene Datenspeicherung
                        zur Geschäftsabwicklung oder die Daten unterliegen der gesetzlichen Aufbewahrungspflicht.</p>
                    <p>Für diese Zwecke kontaktieren Sie bitte unseren Datenschutzbeauftragen (Kontaktdaten: am Ende der Datenschutzerklärung).</p>
                    <p>Um eine Datensperre jederzeit berücksichtigen zu können, ist es erforderlich, die Daten für Kontrollzwecke in einer Sperrdatei vorzuhalten. Besteht keine gesetzliche Archivierungspflicht, können Sie auch die Löschung der Daten verlangen. Anderenfalls
                        sperren wir die Daten, sofern Sie dies wünschen.</p>
                    <p><strong>Änderung unserer Datenschutzerklärung</strong></p>
                    <p>Um zu gewährleisten, dass unsere Datenschutzerklärung stets den aktuellen gesetzlichen Vorgaben entspricht, behalten wir uns jederzeit Änderungen vor. Das gilt auch für den Fall, dass die Datenschutzerklärung aufgrund neuer oder überarbeiteter Leistungen,
                        zum Beispiel neuer Serviceleistungen, angepasst werden muss. Die neue Datenschutzerklärung greift dann bei Ihrem nächsten Besuch auf unserem Angebot.</p>
                </Segment>
            </Container>
        </Segment>
    );
}

export default Impressum;
