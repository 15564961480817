import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Container, Icon, Image, Menu, Segment, Sidebar } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import logoImg from '../../img/logo.png';
import OfisoFooter from './ofisoFooter';

function OfisoSidebar(props) {
    function jumpTo(route) {
        setShowSidebar(false);
        props.history.push(route);
    }

    const [showSidebar, setShowSidebar] = useState(false);
    const [hideSidebar, setHideSidebar] = useState(true);
    return (
        <div>
            <Sidebar
                as={Menu}
                animation='push'
                inverted
                onHide={() => setShowSidebar(false)}
                vertical
                visible={showSidebar}
            >
                <Menu.Item>
                    <Image size='tiny' src={logoImg} />
                </Menu.Item>
                <Menu.Item
                    as='a'
                    active={props.location.pathname === '/'}
                    onClick={() => jumpTo('/')}>
                    OFISO
                </Menu.Item>
                <Menu.Item
                    as='a'
                    active={props.location.pathname === '/impressum'}
                    onClick={() => jumpTo('/impressum')}>
                    Impressum
                </Menu.Item>
            </Sidebar>

            <Sidebar.Pusher dimmed={showSidebar}>
                <Segment
                    inverted
                    textAlign='center'
                    style={{ minHeight: 100, padding: '1em 0em' }}
                    vertical
                >
                    <Container>
                        <Menu inverted pointing secondary size='large'>
                            <Menu.Item onClick={() => {
                                setShowSidebar(hideSidebar && !showSidebar);
                                setHideSidebar(!hideSidebar);
                            }}>
                                <Icon name='sidebar' />
                            </Menu.Item>
                        </Menu>
                    </Container>
                </Segment>
                {props.children}
                <OfisoFooter/>
            </Sidebar.Pusher>
        </div>
    )
}

OfisoSidebar.propTypes = {
    history: PropTypes.object,
    fixed: PropTypes.bool,
    children: PropTypes.node
};

export default withRouter(OfisoSidebar);
