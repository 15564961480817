import React from 'react';

import { render } from 'react-dom';
import { HashRouter } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import './views/layouts/app.css';

import { App } from './views/layouts';
import history from './state/history';

const RootHtml = () => (
    <HashRouter history={history}>
      <App/>
    </HashRouter>
);

render(<RootHtml/>, document.getElementById('react-root'));
