import React from 'react';

import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Grid, Header, Image, List, Segment } from 'semantic-ui-react';
import OfisoHeader from '../layouts/ofisoHeader';
import homeImg from '../../img/home.jpg';
import detailImg from '../../img/tool_detail.jpg';

function Home() {
    return (
        <div>
            <OfisoHeader title={"OFISO"} subtitle={"Objekt-Findungs-SOftware"}/>
            <Segment style={{ padding: '8em 0em' }} vertical>
                <Grid container stackable verticalAlign='middle'>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Header as='h3'>
                                Motivation
                            </Header>
                            <p>
                                Seit 1991 verkaufe ich Softwarel&ouml;sungen f&uuml;r Handwerker und Dienstleister.
                                <br/>
                                Dies waren bis jetzt meist Standardprodukte. Ausschlaggebend f&uuml;r mein erstes selbst entwickeltes Softwareprogramm war der Wunsch der Firma Beller aus Heidenheim  nach einem System mit welchem einfach und schnell erfasst werden konnte, welches Werkzeug von welchem Mitarbeiter entnommen wurde. Dadurch sollte dem „Werkzeugschwund“ begegnet werden.
                            </p>
                            <p>
                                Die auf dem Markt vorhandenen Programme hatten meist zu viele Extras, was sie in der Bedienung komplizierter machte und auch den Preis in die H&ouml;he trieb. Es war einfach kein System zu finden, mit dem durch einfache Bedienung der gew&uuml;nschte Zweck erf&uuml;llt werden konnte.</p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
            <Segment style={{ padding: '8em 0em' }} vertical>
                <Grid container stackable verticalAlign='middle'>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Header as='h3'>
                                OFISO Werkzeug
                            </Header>
                            <p>
                                Daher entschlossen wir uns zur Entwicklung von OFISO-Werkzeug. OFISO-Werkzeug ist einfach und praktisch. Ein echter Mehrwert durch Geld- und Zeitvorteile.
                            </p>
                            <p>
                                OFISO Werkzeug l&auml;uft seit Sommer 2006 bei der Firma Beller zu deren vollsten Zufriedenheit und hat sich in k&uuml;rzester Zeit amortisiert.
                            </p>
                            <p>
                                Vor kurzem wurde OFISO Werkzeug als <b>OFISO's ToolBoss</b> modernisiert und unterstützt nun das Nutzen von Tablets & Smartphones zur Administration des Systems.
                            </p>
                            <p>
                                Dinge die Ihre Arbeit komplizierter machen gibt es genug, also lassen Sie lieber die Finger von Programmen die zwar unendlich viel k&ouml;nnen, Sie aber daf&uuml;r in ein unheimlich enges Korsett zw&auml;ngen.
                            </p>
                        </Grid.Column>
                        <Grid.Column floated='right' width={8}>
                            <Image centered bordered rounded size='medium' src={homeImg} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Image centered bordered rounded size='medium' src={detailImg} />
                        </Grid.Column>
                        <Grid.Column floated='right' width={8}>
                            <Header as='h3'>
                                Grundsätze
                            </Header>
                            <List bulleted>
                                <List.Item> Einfach und praktisch </List.Item>
                                <List.Item> echter Mehrwert durch Geld- oder Zeitvorteile </List.Item>
                            </List>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </div>
    );
}

export default compose(
    withRouter,
)(Home);
