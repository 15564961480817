import React from 'react';

import { Container, Grid, Header, List, Segment } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

function OfisoFooter(props) {
    function jumpTo(route) {
        props.history.push(route);
    }

    return (
        <Segment inverted vertical style={{ padding: '5em 0em' }}>
            <Container>
                <Grid divided inverted stackable>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <Header inverted as='h4' content='About' />
                            <List link inverted>
                                <List.Item as='a' onClick={() => jumpTo('/')}>Home</List.Item>
                                <List.Item as='a' onClick={() => jumpTo('/impressum')}>Impressum</List.Item>
                            </List>
                        </Grid.Column>
                        <Grid.Column width={7}>
                            <Header as='h4' inverted>
                                OFISO
                            </Header>
                            <p>
                                Objekt-Findungs-SOftware
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </Segment>
    );
}

export default withRouter(OfisoFooter);
