import React from 'react';
import PropTypes from 'prop-types';

import { Container, Header, Segment } from 'semantic-ui-react';

function OfisoHeader(props) {
    return (
        <Segment
            inverted
            textAlign='center'
            style={{ minHeight: 75, padding: '1em 0em' }}
            vertical
        >
            <Container text>
                <Header
                    as='h1'
                    content={props.title}
                    inverted
                    style={{
                        fontSize: '4em',
                        fontWeight: 'normal',
                        marginBottom: 0,
                    }}
                />
                <Header
                    as='h2'
                    content={props.subtitle}
                    inverted
                    style={{
                        fontSize: '1.7em',
                        fontWeight: 'normal',
                        marginBottom: '1em'
                    }}
                />
            </Container>
        </Segment>
    );
}

OfisoHeader.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string
};

export default OfisoHeader;
