import React from 'react';
import PropTypes from 'prop-types';

import { Container, Image, Menu, Segment } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import logoImg from '../../img/logo.png';

function OfisoMenu(props) {
    function jumpTo(route) {
        props.history.push(route);
    }

    return (
        <Segment
            inverted
            textAlign='center'
            style={{ minHeight: 100, padding: '1em 0em' }}
            vertical
        >
            <Menu
                inverted
                pointing
                secondary
                size='large'
            >
                <Menu.Item>
                    <Image size='tiny' src={logoImg} />
                </Menu.Item>
                <Container>
                    <Menu.Item
                        as='a'
                        active={props.location.pathname === '/'}
                        onClick={() => jumpTo('/')}>
                        OFISO
                    </Menu.Item>
                    <Menu.Item
                        as='a'
                        active={props.location.pathname === '/impressum'}
                        onClick={() => jumpTo('/impressum')}>
                        Impressum
                    </Menu.Item>
                </Container>
            </Menu>
        </Segment>
    );
}

OfisoMenu.propTypes = {
    history: PropTypes.object,
    fixed: PropTypes.bool
};

export default withRouter(OfisoMenu);
