import { Home, Impressum } from '../views/pages';

const routes = [
    {
        path: '/',
        exact: true,
        component: Home
    },
    {
        path: '/impressum',
        component: Impressum
    }
];

export default routes;
